import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";

export interface LanguageChangeState {
    language: string;
    provider: Object;
}

const initialState: LanguageChangeState = {
    language : "en",
    provider:{}
}

const languageChangeSlice = createSlice({
    name: "languageChange",
    initialState,
    reducers: {
        [`${ACTIONS.LANGUAGE_CHANGED}`]: (state, action: PayloadAction<any>) => {
            return { ...state, language: action.payload }
        },
        [`${ACTIONS.PROVIDERCONFIG}`]: (state, action: PayloadAction<any>) => {
            return { ...state, provider: action.payload }
        }
    }
});

export const { LANGUAGE_CHANGED } = languageChangeSlice.actions;
export default languageChangeSlice.reducer;
