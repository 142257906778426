import { createAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";
import { Dispatch } from "redux";

const languageChanged = createAction<any[]>(`languageChange/${ACTIONS.LANGUAGE_CHANGED}`);
const providerUpdate = createAction<any[]>(`languageChange/${ACTIONS.PROVIDERCONFIG}`);

export const setLanguageChanged: any = (value) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(languageChanged(value));
        } catch (error) {
            // Handle errors
            console.error("Error", error);
        }
    };
};

export const setProviderUpdate: any = (value) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(providerUpdate(value));
        } catch (error) {
            // Handle errors
            console.error("Error", error);
        }
    };
};;