import dayjs from 'dayjs';
export const DEFAULT_DATE_FORMAT="DD-MM-YYYY";

export const DEFAULT_FUNCTION = (e:any, val: any = "") => {console.log(e,val)}

interface BrandedFeature {
  subCode: string;
  commercialName: string;
}

// export const TripType = {
//   MULTICITY: "MultiCity",
//   ROUNDTRIP: "Return",
//   ONEWAY: "OneWay",
//   OPENJAW: "OpenJaw"
// }

// export const FlyingType = {
//   FLYING_FROM: "FlyingFrom",
//   FLYING_TO: "FlyingTo"
// }

// export const FlightLogo = {
//   UK: "UK",
//   HR: "HR",
//   WY: "WY",
//   WB: "WB",
//   GP: "GP",
//   TN: "TN",
//   SB: "SB",
//   AD: "AD"
// }

// export const QuotesDeletedStatus = {
//   STATUS: "Quote is deleted successfully"
// }

// export const statuses = {
//   "SUCCESS": "SUCCESS",
//   "TICKETISSUANCEFAILED": "TICKETISSUANCEFAILED",
//   "FAILED": "FAILED"
// }


// export const Penalties = {
//   FARE_RULES_ERROR: "FareRulesError",
//   STR_FARE_RULES_ERROR: "StructureFareRulesError"
// }

// export const SearchWidgetInput = {
//   AIRPORT: "Airport",
//   ADULT: "Adult",
//   CHILD: "Child",
//   INFANTINLAP: "InfantInLap"
// }

// export const PassengerType = {
//   ADULT: "Adult",
//   CHILD: "Child",
//   INFANTINLAP: "InfantInLap"

// }

// export const BookingHead = {
//   NEW_SEARCH: "New Search",
//   SEARCH_RESULTS: "Search Results",
//   ITINENARY: "Itinerary",
//   REQUEST_SUMMARY: "Request Summary"
// }

// export const MySavedQuotesTab = {
//   TODAY: "Today",
//   YESTERDAY: "Yesterday",
//   LASTWEEK: "Last Week",
//   ARCHIEVED: "Archived"
// }


// export const searchApiOrgId = {
//   ORG_ID: "O1",
//   AGENCY_ID: "A1",
//   AGENCY_GRP_ID: "AG1"

// }


// export const monthNames = ["Jan", "Feb", "March", "April", "May", "Jun",
//   "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
// ];

// export const fullmonthNames = ["January", "February", "March", "April", "May",
//   "June", "July", "August", "September", "October", "November", "December",
// ];

// export const mealArr = [
//   { code: "Select Meal", description: "Select Meal" },
//   { code: "AVML", description: "ASIAN VEG" },
//   { code: "BLML", description: "BLAND MEAL" },
//   { code: "CHML", description: "CHILD MEAL" },
//   { code: "DBML", description: "DIABETIC MEAL" },
//   { code: "GFML", description: "GLUTEN INTOLERANT/Free" },
//   { code: "HNML", description: "HINDU" },
//   { code: "KSML", description: "KOSHER" },
//   { code: "LCML", description: "LOW CALORIE" },
//   { code: "LFML", description: "LOW FAT MEAL/Cholesterol" },
//   { code: "LSML", description: "LOW SALT MEAL/Sodium" },
//   { code: "MOML", description: "MUSLIM MEAL" },
//   { code: "NLML", description: "NON LACTOSE MEAL" },
//   { code: "NSML", description: "NO SALT MEAL" },
//   { code: "PFML", description: "PEANUT FREE MEAL" },
//   { code: "VGML", description: "VEG VEGAN MEAL" },
//   { code: "VLML", description: "VEG LACTO-OVO" },
//   { code: "VOML", description: "VEGETARIAN ORIENTAL MEAL" },
//   { code: "RVML", description: "RAW VEGETARIAN MEAL" }
// ]

// export const assistanceArr = [
//   { code: "Select Assistance", description: "Select Assistance" },
//   { code: "MAAS", description: "Meet and Assist " },
//   { code: "BLND", description: "Blind" },
//   { code: "DEAF", description: "Deaf" }

// ]

// export const wheelChairArr = [
//   { code: "Select Wheelchair", description: "Select Wheelchair" },
//   { code: "WCHR", description: "Wheelchair/Passenger can walk up stairs" },
//   { code: "WCHS", description: "Wheelchair/Passenger can walk to seat" },
//   { code: "WCHC", description: "Wheelchair/Passenger must be carried" },
//   { code: "WCOB", description: "Wheelchair on board" }

// ]



// export const airlineCode =
//   [
//     { value: "UA", description: "United Mileage Plus" },
//     {
//       value: "JP", description: "Adria Miles & More"
//     },
//     {
//       value: "A3", description: "Aegean Miles & Bonus"
//     },
//     {
//       value: "RE", description: "Aer Arann Silver Club "
//     },
//     {
//       value: "EI", description: "Aer Lingus Gold Circle Club"
//     },
//     {
//       value: "SU", description: "Aeroflot Bonus"
//     },
//     {
//       value: "2K", description: "AeroGal Aeromillas"
//     },
//     {
//       value: "AR", description: "Aerolineas Argentinas Plus"
//     },
//     {
//       value: "AM", description: "Aeromexico Club Premier"
//     },
//     {
//       value: "VH", description: "Aeropostal Aeropass"
//     },
//     {
//       value: "VV", description: "Aerosvit Meridian Club"
//     },
//     {
//       value: "ZI", description: "Aigle Azur Plus"
//     },
//     {
//       value: "AH", description: "Air Algerie Plus"
//     }, {
//       value: "KC", description: "Air Astana Nomad Club"
//     }, {
//       value: "AC", description: "Air Canada Aeroplan "
//     }, {
//       value: "CA", description: "Air China Companion"
//     },
//     {
//       value: "YN", description: "Air Creebec Aeroplan"
//     },
//     {
//       value: "EN", description: " Air Dolomiti Miles &amp; More"
//     },
//     {
//       value: "UX", description: "Air Europa Flying Blue"
//     },
//     {
//       value: "AF", description: "Air France Flying Blue"
//     },
//     {
//       value: "AI", description: " Air India Flying Returns"
//     },
//     {
//       value: "JM", description: "Air Jamaica 7th Heaven"
//     },
//     {
//       value: "NX", description: "Air Macau Privileges"
//     },
//     {
//       value: "MD", description: "Air Madagascar Namako "
//     },
//     {
//       value: "KM", description: "Air Malta Flypass"
//     },
//     {
//       value: "MK", description: "Mauritius KestrelFlyer "
//     },
//     {
//       value: "NZ", description: "Air New Zealand Air Points"
//     },
//     {
//       value: "YW", description: "Air Nostrum Iberia"
//     },
//     {
//       value: "AP", description: "Air One Qualiflyer"
//     },
//     {
//       value: "HM", description: "Air Seychelles "
//     },
//     {
//       value: "TM", description: "Air Tahiti Nui Club Tiare"
//     },
//     {
//       value: "NF", description: "Air Vanuatu -Qantas Frequent Flyer"
//     },
//     {
//       value: "UM", description: "Air Zimbabwe Rainbow Club"
//     },
//     {
//       value: "BT", description: "airBaltic Miles"
//     },
//     {
//       value: "AB", description: "airberlin topbonus"
//     },
//     {
//       value: "SB", description: "Aircalin Flying Blue"
//     },
//     {
//       value: "A5", description: "Airlinair Flying Blue"
//     },
//     {
//       value: "FL", description: "AirTran A+ Rewards"
//     },
//     {
//       value: "AS", description: "Alaska Mileage Plan"
//     },
//     {
//       value: "NH", description: "All Nippon Mileage Club"
//     },
//     {
//       value: "AA", description: "American AAdvantage"
//     },
//     {
//       value: "R7", description: "Aserca Airlines Privilege"
//     },
//     {
//       value: "OZ", description: "Asiana Club"
//     },
//     {
//       value: "OS", description: "Austrian Miles &amp; More"
//     },
//     {
//       value: "PG", description: "Bangkok Airways FlyerBonus"
//     }, {
//       value: "UP", description: "Bahamasair Flyer "
//     },
//     {
//       value: "AV", description: "Avianca LifeMiles"
//     },
//     {
//       value: "JA", description: "BH Airlines Miles &amp; Smiles"
//     },
//     {
//       value: "KF", description: " Blue1 Eurobonus "
//     },
//     {
//       value: "BD", description: "bmi diamond club"
//     },
//     {
//       value: "FQ", description: "Brindabella - Qantas Frequent Flyer"
//     },
//     {
//       value: "BA", description: "British Airways Executive Club"
//     },
//     {
//       value: "FB", description: "Bulgaria Air FlyFB Bonus Points"
//     },
//     {
//       value: "5T", description: "Canadian North Aeroplan"
//     }
//   ]

// export const bookingStatus = [
//   { code: "All", description: "ALL" },
//   { code: "NP", description: "ON HOLD" },
//   { code: "PNP", description: "PAID" },
//   { code: "PT", description: "TICKETED" },
//   { code: "RF", description: "REFUNDED" },
//   { code: "NTC", description: "CANCELLED" },
//   { code: "SC", description: "SCHEDULE CHANGE" },
//   { code: "TF", description: "PAID" },
//   { code: "PD", description: "PAID" },

//   { code: "NTC", description: "Non Ticketed PNR Cancelled (NTC)" },
//   { code: "TC", description: "Ticketed PNR Cancelled (TC)" },
//   { code: "PNTC", description: "Paid Non Ticketed PNR Cancelled (PNTC)" }
// ]
// export const bookingStatusTabData = [
//   { code: "All", description: "ALL" },
//   { code: "NP", description: "ON HOLD" },
//   { code: "PNP", description: "PAID" },
//   { code: "PT", description: "TICKETED" },
//   { code: "NTC", description: "CANCELLED" }
// ]


// export const fareStatus = {
//   DisplayedButNotOffered: "Not offered",
//   NotOffered: "Not offered",
//   ServiceIsFree: "Free",
//   Chargeable: "Chargeable"
// }

// export const amedeousPhoneTyle = [
//   { code: "Phone", description: "Phone" },
//   { code: "Mobile", description: "Mobile" },
//   { code: "Fax", description: "Fax" },
//   { code: "Home", description: "Home" },
//   { code: "Business", description: "Business" },
//   { code: "Agency", description: "Agency" },
//   { code: "Unknown", description: "Other" }


// ]
// export const sabrePhoneType = [
//   // { code: "Fax", description: "Fax" },
//   { code: "Mobile", description: "Mobile" },
//   { code: "Home", description: "Home" },
//   { code: "Business", description: "Business" },
//   // { code: "Agency", description: "Agency" }

// ]



// export const counrtyCode = [
//   {
//     id: "1",
//     name: "Afghanistan",
//     dial_code: "+93",
//     code: "af",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/af.png"
//   },
//   {
//     id: "2",
//     name: "Albania",
//     dial_code: "+355",
//     code: "al",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/al.png"
//   },
//   {
//     id: "3",
//     name: "Algeria",
//     dial_code: "+213",
//     code: "dz",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/dz.png"
//   },
//   {
//     id: "4",
//     name: "AmericanSamoa",
//     dial_code: "+1684",
//     code: "as",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/as.png"
//   },
//   {
//     id: "5",
//     name: "Andorra",
//     dial_code: "+376",
//     code: "ad",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ad.png"
//   },
//   {
//     id: "6",
//     name: "Angola",
//     dial_code: "+244",
//     code: "ao",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ao.png"
//   },
//   {
//     id: "7",
//     name: "Anguilla",
//     dial_code: "+1264",
//     code: "ai",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ai.png"
//   },
//   {
//     id: "9",
//     name: "Antigua and Barbuda",
//     dial_code: "+1268",
//     code: "ag",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ag.png"
//   },
//   {
//     id: "10",
//     name: "Argentina",
//     dial_code: "+54",
//     code: "ar",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ar.png"
//   },
//   {
//     id: "11",
//     name: "Armenia",
//     dial_code: "+374",
//     code: "am",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/am.png"
//   },
//   {
//     id: "12",
//     name: "Aruba",
//     dial_code: "+297",
//     code: "aw",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/aw.png"
//   },
//   {
//     id: "13",
//     name: "Australia",
//     dial_code: "+61",
//     code: "au",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/au.png"
//   },
//   {
//     id: "14",
//     name: "Austria",
//     dial_code: "+43",
//     code: "at",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/at.png"
//   },
//   {
//     id: "15",
//     name: "Azerbaijan",
//     dial_code: "+994",
//     code: "az",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/az.png"
//   },
//   {
//     id: "16",
//     name: "Bahamas",
//     dial_code: "+1242",
//     code: "bs",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bs.png"
//   },
//   {
//     id: "17",
//     name: "Bahrain",
//     dial_code: "+973",
//     code: "bh",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bh.png"
//   },
//   {
//     id: "18",
//     name: "Bangladesh",
//     dial_code: "+880",
//     code: "bd",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bd.png"
//   },
//   {
//     id: "19",
//     name: "Barbados",
//     dial_code: "+1246",
//     code: "bb",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bb.png"
//   },
//   {
//     id: "20",
//     name: "Belarus",
//     dial_code: "+375",
//     code: "by",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/by.png"
//   },
//   {
//     id: "21",
//     name: "Belgium",
//     dial_code: "+32",
//     code: "be",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/be.png"
//   },
//   {
//     id: "22",
//     name: "Belize",
//     dial_code: "+501",
//     code: "bz",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bz.png"
//   },
//   {
//     id: "23",
//     name: "Benin",
//     dial_code: "+229",
//     code: "bj",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bj.png"
//   },
//   {
//     id: "24",
//     name: "Bermuda",
//     dial_code: "+1441",
//     code: "bm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bm.png"
//   },
//   {
//     id: "25",
//     name: "Bhutan",
//     dial_code: "+975",
//     code: "bt",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bt.png"
//   },
//   {
//     id: "26",
//     name: "Bolivia, Plurinational State of",
//     dial_code: "+591",
//     code: "bo",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bo.png"
//   },
//   {
//     id: "27",
//     name: "Bosnia and Herzegovina",
//     dial_code: "+387",
//     code: "ba",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ba.png"
//   },
//   {
//     id: "28",
//     name: "Botswana",
//     dial_code: "+267",
//     code: "bw",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bw.png"
//   },
//   {
//     id: "29",
//     name: "Brazil",
//     dial_code: "+55",
//     code: "br",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/br.png"
//   },
//   {
//     id: "30",
//     name: "British Indian Ocean Territory",
//     dial_code: "+246",
//     code: "io",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/io.png"
//   },
//   {
//     id: "31",
//     name: "Brunei Darussalam",
//     dial_code: "+673",
//     code: "bn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bn.png"
//   },
//   {
//     id: "32",
//     name: "Bulgaria",
//     dial_code: "+359",
//     code: "bg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bg.png"
//   },
//   {
//     id: "33",
//     name: "Burkina Faso",
//     dial_code: "+226",
//     code: "bf",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bf.png"
//   },
//   {
//     id: "34",
//     name: "Burundi",
//     dial_code: "+257",
//     code: "bi",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bi.png"
//   },
//   {
//     id: "35",
//     name: "Cambodia",
//     dial_code: "+855",
//     code: "kh",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/kh.png"
//   },
//   {
//     id: "36",
//     name: "Cameroon",
//     dial_code: "+237",
//     code: "cm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cm.png"
//   },
//   {
//     id: "37",
//     name: "Canada",
//     dial_code: "+1",
//     code: "ca",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ca.png"
//   },
//   {
//     id: "38",
//     name: "Cape Verde",
//     dial_code: "+238",
//     code: "cv",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cv.png"
//   },
//   {
//     id: "39",
//     name: "Cayman Islands",
//     dial_code: "+345",
//     code: "ky",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ky.png"
//   },
//   {
//     id: "40",
//     name: "Central African Republic",
//     dial_code: "+236",
//     code: "cf",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cf.png"
//   },
//   {
//     id: "41",
//     name: "Chad",
//     dial_code: "+235",
//     code: "td",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/td.png"
//   },
//   {
//     id: "42",
//     name: "Chile",
//     dial_code: "+56",
//     code: "cl",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cl.png"
//   },
//   {
//     id: "43",
//     name: "China",
//     dial_code: "+86",
//     code: "cn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cn.png"
//   },
//   {
//     id: "44",
//     name: "Christmas Island",
//     dial_code: "+61",
//     code: "cx",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cx.png"
//   },
//   {
//     id: "45",
//     name: "Cocos (Keeling) Islands",
//     dial_code: "+61",
//     code: "cc",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cc.png"
//   },
//   {
//     id: "46",
//     name: "Colombia",
//     dial_code: "+57",
//     code: "co",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/co.png"
//   },
//   {
//     id: "47",
//     name: "Comoros",
//     dial_code: "+269",
//     code: "km",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/km.png"
//   },
//   {
//     id: "48",
//     name: "Congo",
//     dial_code: "+242",
//     code: "cg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cg.png"
//   },
//   {
//     id: "49",
//     name: "Congo, The Democratic Republic of the",
//     dial_code: "+243",
//     code: "cd",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cd.png"
//   },
//   {
//     id: "50",
//     name: "Cook Islands",
//     dial_code: "+682",
//     code: "ck",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ck.png"
//   },
//   {
//     id: "51",
//     name: "Costa Rica",
//     dial_code: "+506",
//     code: "cr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cr.png"
//   },
//   {
//     id: "52",
//     name: "Cote d'Ivoire",
//     dial_code: "+225",
//     code: "ci",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ci.png"
//   },
//   {
//     id: "53",
//     name: "Croatia",
//     dial_code: "+385",
//     code: "hr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/hr.png"
//   },
//   {
//     id: "54",
//     name: "Cuba",
//     dial_code: "+53",
//     code: "cu",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cu.png"
//   },
//   {
//     id: "55",
//     name: "Cyprus",
//     dial_code: "+357",
//     code: "cy",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cy.png"
//   },
//   {
//     id: "56",
//     name: "Czech Republic",
//     dial_code: "+420",
//     code: "cz",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/cz.png"
//   },
//   {
//     id: "57",
//     name: "Denmark",
//     dial_code: "+45",
//     code: "dk",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/dk.png"
//   },
//   {
//     id: "58",
//     name: "Djibouti",
//     dial_code: "+253",
//     code: "dj",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/dj.png"
//   },
//   {
//     id: "59",
//     name: "Dominica",
//     dial_code: "+1767",
//     code: "dm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/dm.png"
//   },
//   {
//     id: "60",
//     name: "Dominican Republic",
//     dial_code: "+1849",
//     code: "do",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/do.png"
//   },
//   {
//     id: "61",
//     name: "Ecuador",
//     dial_code: "+593",
//     code: "ec",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ec.png"
//   },
//   {
//     id: "62",
//     name: "Egypt",
//     dial_code: "+20",
//     code: "eg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/eg.png"
//   },
//   {
//     id: "63",
//     name: "El Salvador",
//     dial_code: "+503",
//     code: "sv",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sv.png"
//   },
//   {
//     id: "64",
//     name: "Equatorial Guinea",
//     dial_code: "+240",
//     code: "gq",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gq.png"
//   },
//   {
//     id: "65",
//     name: "Eritrea",
//     dial_code: "+291",
//     code: "er",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/er.png"
//   },
//   {
//     id: "66",
//     name: "Estonia",
//     dial_code: "+372",
//     code: "ee",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ee.png"
//   },
//   {
//     id: "67",
//     name: "Ethiopia",
//     dial_code: "+251",
//     code: "et",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/et.png"
//   },
//   {
//     id: "68",
//     name: "Falkland Islands (Malvinas)",
//     dial_code: "+500",
//     code: "fk",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/fk.png"
//   },
//   {
//     id: "69",
//     name: "Faroe Islands",
//     dial_code: "+298",
//     code: "fo",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/fo.png"
//   },
//   {
//     id: "70",
//     name: "Fiji",
//     dial_code: "+679",
//     code: "fj",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/fj.png"
//   },
//   {
//     id: "71",
//     name: "Finland",
//     dial_code: "+358",
//     code: "fi",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/fi.png"
//   },
//   {
//     id: "72",
//     name: "France",
//     dial_code: "+33",
//     code: "fr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/fr.png"
//   },
//   {
//     id: "73",
//     name: "French Guiana",
//     dial_code: "+594",
//     code: "gf",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gf.png"
//   },
//   {
//     id: "74",
//     name: "French Polynesia",
//     dial_code: "+689",
//     code: "pf",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pf.png"
//   },
//   {
//     id: "75",
//     name: "Gabon",
//     dial_code: "+241",
//     code: "ga",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ga.png"
//   },
//   {
//     id: "76",
//     name: "Gambia",
//     dial_code: "+220",
//     code: "gm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gm.png"
//   },
//   {
//     id: "77",
//     name: "Georgia",
//     dial_code: "+995",
//     code: "ge",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ge.png"
//   },
//   {
//     id: "78",
//     name: "Germany",
//     dial_code: "+49",
//     code: "de",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/de.png"
//   },
//   {
//     id: "79",
//     name: "Ghana",
//     dial_code: "+233",
//     code: "gh",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gh.png"
//   },
//   {
//     id: "80",
//     name: "Gibraltar",
//     dial_code: "+350",
//     code: "gi",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gi.png"
//   },
//   {
//     id: "81",
//     name: "Greece",
//     dial_code: "+30",
//     code: "gr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gr.png"
//   },
//   {
//     id: "82",
//     name: "Greenland",
//     dial_code: "+299",
//     code: "gl",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gl.png"
//   },
//   {
//     id: "83",
//     name: "Grenada",
//     dial_code: "+1473",
//     code: "gd",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gd.png"
//   },
//   {
//     id: "84",
//     name: "Guadeloupe",
//     dial_code: "+590",
//     code: "gp",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gp.png"
//   },
//   {
//     id: "85",
//     name: "Guam",
//     dial_code: "+1671",
//     code: "gu",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gu.png"
//   },
//   {
//     id: "86",
//     name: "Guatemala",
//     dial_code: "+502",
//     code: "gt",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gt.png"
//   },
//   {
//     id: "87",
//     name: "Guernsey",
//     dial_code: "+44",
//     code: "gg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gg.png"
//   },
//   {
//     id: "88",
//     name: "Guinea",
//     dial_code: "+224",
//     code: "gn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gn.png"
//   },
//   {
//     id: "89",
//     name: "Guinea-Bissau",
//     dial_code: "+245",
//     code: "gw",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gw.png"
//   },
//   {
//     id: "90",
//     name: "Guyana",
//     dial_code: "+592",
//     code: "gy",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gy.png"
//   },
//   {
//     id: "91",
//     name: "Haiti",
//     dial_code: "+509",
//     code: "ht",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ht.png"
//   },
//   {
//     id: "92",
//     name: "Holy See (Vatican City State)",
//     dial_code: "+379",
//     code: "va",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/va.png"
//   },
//   {
//     id: "93",
//     name: "Honduras",
//     dial_code: "+504",
//     code: "hn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/hn.png"
//   },
//   {
//     id: "94",
//     name: "Hong Kong",
//     dial_code: "+852",
//     code: "hk",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/hk.png"
//   },
//   {
//     id: "95",
//     name: "Hungary",
//     dial_code: "+36",
//     code: "hu",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/hu.png"
//   },
//   {
//     id: "96",
//     name: "Iceland",
//     dial_code: "+354",
//     code: "is",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/is.png"
//   },
//   {
//     id: "97",
//     name: "India",
//     dial_code: "+91",
//     code: "in",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/in.png"
//   },
//   {
//     id: "98",
//     name: "Indonesia",
//     dial_code: "+62",
//     code: "id",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/id.png"
//   },
//   {
//     id: "99",
//     name: "Iran, Islamic Republic of",
//     dial_code: "+98",
//     code: "ir",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ir.png"
//   },
//   {
//     id: "101",
//     name: "Ireland",
//     dial_code: "+353",
//     code: "ie",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ie.png"
//   },
//   {
//     id: "102",
//     name: "Isle of Man",
//     dial_code: "+44",
//     code: "im",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/im.png"
//   },
//   {
//     id: "103",
//     name: "Israel",
//     dial_code: "+972",
//     code: "il",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/il.png"
//   },
//   {
//     id: "105",
//     name: "Italy",
//     dial_code: "+39",
//     code: "it",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/it.png"
//   },
//   {
//     id: "106",
//     name: "Jamaica",
//     dial_code: "+1876",
//     code: "jm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/jm.png"
//   },
//   {
//     id: "107",
//     name: "Japan",
//     dial_code: "+81",
//     code: "jp",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/jp.png"
//   },
//   {
//     id: "108",
//     name: "Jersey",
//     dial_code: "+44",
//     code: "je",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/je.png"
//   },
//   {
//     id: "109",
//     name: "Jordan",
//     dial_code: "+962",
//     code: "jo",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/jo.png"
//   },
//   {
//     id: "110",
//     name: "Kazakhstan",
//     dial_code: "+7",
//     code: "kz",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/kz.png"
//   },
//   {
//     id: "111",
//     name: "Kenya",
//     dial_code: "+254",
//     code: "ke",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ke.png"
//   },
//   {
//     id: "112",
//     name: "Kiribati",
//     dial_code: "+686",
//     code: "ki",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ki.png"
//   },
//   {
//     id: "113",
//     name: "Korea, Democratic People's Republic of",
//     dial_code: "+850",
//     code: "kp",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/kp.png"
//   },
//   {
//     id: "114",
//     name: "Korea, Republic of",
//     dial_code: "+82",
//     code: "kr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/kr.png"
//   },
//   {
//     id: "115",
//     name: "Kuwait",
//     dial_code: "+965",
//     code: "kw",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/kw.png"
//   },
//   {
//     id: "116",
//     name: "Kyrgyzstan",
//     dial_code: "+996",
//     code: "kg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/kg.png"
//   },
//   {
//     id: "117",
//     name: "Lao People's Democratic Republic",
//     dial_code: "+856",
//     code: "la",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/la.png"
//   },
//   {
//     id: "118",
//     name: "Latvia",
//     dial_code: "+371",
//     code: "lv",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/lv.png"
//   },
//   {
//     id: "119",
//     name: "Lebanon",
//     dial_code: "+961",
//     code: "lb",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/lb.png"
//   },
//   {
//     id: "120",
//     name: "Lesotho",
//     dial_code: "+266",
//     code: "ls",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ls.png"
//   },
//   {
//     id: "121",
//     name: "Liberia",
//     dial_code: "+231",
//     code: "lr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/lr.png"
//   },
//   {
//     id: "122",
//     name: "Libyan Arab Jamahiriya",
//     dial_code: "+218",
//     code: "ly",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ly.png"
//   },
//   {
//     id: "123",
//     name: "Liechtenstein",
//     dial_code: "+423",
//     code: "li",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/li.png"
//   },
//   {
//     id: "124",
//     name: "Lithuania",
//     dial_code: "+370",
//     code: "lt",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/lt.png"
//   },
//   {
//     id: "125",
//     name: "Luxembourg",
//     dial_code: "+352",
//     code: "lu",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/lu.png"
//   },
//   {
//     id: "126",
//     name: "Macao",
//     dial_code: "+853",
//     code: "mo",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mo.png"
//   },
//   {
//     id: "127",
//     name: "Macedonia, The Former Yugoslav Republic of",
//     dial_code: "+389",
//     code: "mk",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mk.png"
//   },
//   {
//     id: "128",
//     name: "Madagascar",
//     dial_code: "+261",
//     code: "mg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mg.png"
//   },
//   {
//     id: "129",
//     name: "Malawi",
//     dial_code: "+265",
//     code: "mw",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mw.png"
//   },
//   {
//     id: "130",
//     name: "Malaysia",
//     dial_code: "+60",
//     code: "my",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/my.png"
//   },
//   {
//     id: "131",
//     name: "Maldives",
//     dial_code: "+960",
//     code: "mv",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mv.png"
//   },
//   {
//     id: "132",
//     name: "Mali",
//     dial_code: "+223",
//     code: "ml",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ml.png"
//   },
//   {
//     id: "133",
//     name: "Malta",
//     dial_code: "+356",
//     code: "mt",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mt.png"
//   },
//   {
//     id: "134",
//     name: "Marshall Islands",
//     dial_code: "+692",
//     code: "mh",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mh.png"
//   },
//   {
//     id: "135",
//     name: "Martinique",
//     dial_code: "+596",
//     code: "mq",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mq.png"
//   },
//   {
//     id: "136",
//     name: "Mauritania",
//     dial_code: "+222",
//     code: "mr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mr.png"
//   },
//   {
//     id: "137",
//     name: "Mauritius",
//     dial_code: "+230",
//     code: "mu",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mu.png"
//   },
//   {
//     id: "138",
//     name: "Mayotte",
//     dial_code: "+262",
//     code: "yt",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/yt.png"
//   },
//   {
//     id: "139",
//     name: "Mexico",
//     dial_code: "+52",
//     code: "mx",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mx.png"
//   },
//   {
//     id: "140",
//     name: "Micronesia, Federated States of",
//     dial_code: "+691",
//     code: "fm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/fm.png"
//   },
//   {
//     id: "141",
//     name: "Moldova, Republic of",
//     dial_code: "+373",
//     code: "md",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/md.png"
//   },
//   {
//     id: "142",
//     name: "Monaco",
//     dial_code: "+377",
//     code: "mc",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mc.png"
//   },
//   {
//     id: "143",
//     name: "Mongolia",
//     dial_code: "+976",
//     code: "mn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mn.png"
//   },
//   {
//     id: "144",
//     name: "Montenegro",
//     dial_code: "+382",
//     code: "me",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/me.png"
//   },
//   {
//     id: "145",
//     name: "Montserrat",
//     dial_code: "+1664",
//     code: "ms",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ms.png"
//   },
//   {
//     id: "146",
//     name: "Morocco",
//     dial_code: "+212",
//     code: "ma",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ma.png"
//   },
//   {
//     id: "147",
//     name: "Mozambique",
//     dial_code: "+258",
//     code: "mz",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mz.png"
//   },
//   {
//     id: "148",
//     name: "Myanmar",
//     dial_code: "+95",
//     code: "mm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mm.png"
//   },
//   {
//     id: "149",
//     name: "Namibia",
//     dial_code: "+264",
//     code: "na",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/na.png"
//   },
//   {
//     id: "150",
//     name: "Nauru",
//     dial_code: "+674",
//     code: "nr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/nr.png"
//   },
//   {
//     id: "151",
//     name: "Nepal",
//     dial_code: "+977",
//     code: "np",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/np.png"
//   },
//   {
//     id: "152",
//     name: "Netherlands",
//     dial_code: "+31",
//     code: "nl",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/nl.png"
//   },
//   {
//     id: "153",
//     name: "Netherlands Antilles",
//     dial_code: "+599",
//     code: "an",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/an.png"
//   },
//   {
//     id: "154",
//     name: "New Caledonia",
//     dial_code: "+687",
//     code: "nc",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/nc.png"
//   },
//   {
//     id: "155",
//     name: "New Zealand",
//     dial_code: "+64",
//     code: "nz",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/nz.png"
//   },
//   {
//     id: "156",
//     name: "Nicaragua",
//     dial_code: "+505",
//     code: "ni",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ni.png"
//   },
//   {
//     id: "157",
//     name: "Niger",
//     dial_code: "+227",
//     code: "ne",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ne.png"
//   },
//   {
//     id: "158",
//     name: "Nigeria",
//     dial_code: "+234",
//     code: "ng",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ng.png"
//   },
//   {
//     id: "159",
//     name: "Niue",
//     dial_code: "+683",
//     code: "nu",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/nu.png"
//   },
//   {
//     id: "160",
//     name: "Norfolk Island",
//     dial_code: "+672",
//     code: "nf",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/nf.png"
//   },
//   {
//     id: "161",
//     name: "Northern Mariana Islands",
//     dial_code: "+1670",
//     code: "mp",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mp.png"
//   },
//   {
//     id: "162",
//     name: "Norway",
//     dial_code: "+47",
//     code: "no",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/no.png"
//   },
//   {
//     id: "163",
//     name: "Oman",
//     dial_code: "+968",
//     code: "om",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/om.png"
//   },
//   {
//     id: "164",
//     name: "Pakistan",
//     dial_code: "+92",
//     code: "pk",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pk.png"
//   },
//   {
//     id: "165",
//     name: "Palau",
//     dial_code: "+680",
//     code: "pw",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pw.png"
//   },
//   {
//     id: "166",
//     name: "Palestinian Territory, Occupied",
//     dial_code: "+970",
//     code: "ps",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ps.png"
//   },
//   {
//     id: "167",
//     name: "Panama",
//     dial_code: "+507",
//     code: "pa",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pa.png"
//   },
//   {
//     id: "168",
//     name: "Papua New Guinea",
//     dial_code: "+675",
//     code: "pg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pg.png"
//   },
//   {
//     id: "169",
//     name: "Paraguay",
//     dial_code: "+595",
//     code: "py",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/py.png"
//   },
//   {
//     id: "170",
//     name: "Peru",
//     dial_code: "+51",
//     code: "pe",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pe.png"
//   },
//   {
//     id: "171",
//     name: "Philippines",
//     dial_code: "+63",
//     code: "ph",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ph.png"
//   },
//   {
//     id: "172",
//     name: "Pitcairn",
//     dial_code: "+872",
//     code: "pn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pn.png"
//   },
//   {
//     id: "173",
//     name: "Poland",
//     dial_code: "+48",
//     code: "pl",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pl.png"
//   },
//   {
//     id: "174",
//     name: "Portugal",
//     dial_code: "+351",
//     code: "pt",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pt.png"
//   },
//   {
//     id: "175",
//     name: "Puerto Rico",
//     dial_code: "+1939",
//     code: "pr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pr.png"
//   },
//   {
//     id: "176",
//     name: "Qatar",
//     dial_code: "+974",
//     code: "qa",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/qa.png"
//   },
//   {
//     id: "177",
//     name: "Romania",
//     dial_code: "+40",
//     code: "ro",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ro.png"
//   },
//   {
//     id: "178",
//     name: "Russia",
//     dial_code: "+7",
//     code: "ru",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ru.png"
//   },
//   {
//     id: "179",
//     name: "Rwanda",
//     dial_code: "+250",
//     code: "rw",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/rw.png"
//   },
//   {
//     id: "180",
//     name: "Réunion",
//     dial_code: "+262",
//     code: "re",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/re.png"
//   },
//   {
//     id: "181",
//     name: "Saint Barthélemy",
//     dial_code: "+590",
//     code: "bl",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/bl.png"
//   },
//   {
//     id: "182",
//     name: "Saint Helena, Ascension and Tristan Da Cunha",
//     dial_code: "+290",
//     code: "sh",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sh.png"
//   },
//   {
//     id: "183",
//     name: "Saint Kitts and Nevis",
//     dial_code: "+1869",
//     code: "kn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/kn.png"
//   },
//   {
//     id: "184",
//     name: "Saint Lucia",
//     dial_code: "+1758",
//     code: "lc",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/lc.png"
//   },
//   {
//     id: "185",
//     name: "Saint Martin",
//     dial_code: "+590",
//     code: "mf",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/mf.png"
//   },
//   {
//     id: "186",
//     name: "Saint Pierre and Miquelon",
//     dial_code: "+508",
//     code: "pm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/pm.png"
//   },
//   {
//     id: "187",
//     name: "Saint Vincent and the Grenadines",
//     dial_code: "+1784",
//     code: "vc",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/vc.png"
//   },
//   {
//     id: "188",
//     name: "Samoa",
//     dial_code: "+685",
//     code: "ws",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ws.png"
//   },
//   {
//     id: "189",
//     name: "San Marino",
//     dial_code: "+378",
//     code: "sm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sm.png"
//   },
//   {
//     id: "190",
//     name: "Sao Tome and Principe",
//     dial_code: "+239",
//     code: "st",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/st.png"
//   },
//   {
//     id: "191",
//     name: "Saudi Arabia",
//     dial_code: "+966",
//     code: "sa",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sa.png"
//   },
//   {
//     id: "192",
//     name: "Senegal",
//     dial_code: "+221",
//     code: "sn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sn.png"
//   },
//   {
//     id: "193",
//     name: "Serbia",
//     dial_code: "+381",
//     code: "rs",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/rs.png"
//   },
//   {
//     id: "194",
//     name: "Seychelles",
//     dial_code: "+248",
//     code: "sc",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sc.png"
//   },
//   {
//     id: "195",
//     name: "Sierra Leone",
//     dial_code: "+232",
//     code: "sl",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sl.png"
//   },
//   {
//     id: "196",
//     name: "Singapore",
//     dial_code: "+65",
//     code: "sg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sg.png"
//   },
//   {
//     id: "197",
//     name: "Slovakia",
//     dial_code: "+421",
//     code: "sk",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sk.png"
//   },
//   {
//     id: "198",
//     name: "Slovenia",
//     dial_code: "+386",
//     code: "si",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/si.png"
//   },
//   {
//     id: "199",
//     name: "Solomon Islands",
//     dial_code: "+677",
//     code: "sb",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sb.png"
//   },
//   {
//     id: "200",
//     name: "Somalia",
//     dial_code: "+252",
//     code: "so",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/so.png"
//   },
//   {
//     id: "201",
//     name: "South Africa",
//     dial_code: "+27",
//     code: "za",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/za.png"
//   },
//   {
//     id: "202",
//     name: "South Georgia and the South Sandwich Islands",
//     dial_code: "+500",
//     code: "gs",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gs.png"
//   },
//   {
//     id: "203",
//     name: "Spain",
//     dial_code: "+34",
//     code: "es",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/es.png"
//   },
//   {
//     id: "204",
//     name: "Sri Lanka",
//     dial_code: "+94",
//     code: "lk",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/lk.png"
//   },
//   {
//     id: "205",
//     name: "Sudan",
//     dial_code: "+249",
//     code: "sd",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sd.png"
//   },
//   {
//     id: "206",
//     name: "Suriname",
//     dial_code: "+597",
//     code: "sr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sr.png"
//   },
//   {
//     id: "207",
//     name: "Svalbard and Jan Mayen",
//     dial_code: "+47",
//     code: "sj",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sj.png"
//   },
//   {
//     id: "208",
//     name: "Swaziland",
//     dial_code: "+268",
//     code: "sz",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sz.png"
//   },
//   {
//     id: "209",
//     name: "Sweden",
//     dial_code: "+46",
//     code: "se",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/se.png"
//   },
//   {
//     id: "210",
//     name: "Switzerland",
//     dial_code: "+41",
//     code: "ch",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ch.png"
//   },
//   {
//     id: "211",
//     name: "Syrian Arab Republic",
//     dial_code: "+963",
//     code: "sy",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/sy.png"
//   },
//   {
//     id: "212",
//     name: "Taiwan, Province of China",
//     dial_code: "+886",
//     code: "tw",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tw.png"
//   },
//   {
//     id: "213",
//     name: "Tajikistan",
//     dial_code: "+992",
//     code: "tj",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tj.png"
//   },
//   {
//     id: "214",
//     name: "Tanzania, United Republic of",
//     dial_code: "+255",
//     code: "tz",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tz.png"
//   },
//   {
//     id: "215",
//     name: "Thailand",
//     dial_code: "+66",
//     code: "th",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/th.png"
//   },
//   {
//     id: "216",
//     name: "Timor-Leste",
//     dial_code: "+670",
//     code: "tl",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tl.png"
//   },
//   {
//     id: "217",
//     name: "Togo",
//     dial_code: "+228",
//     code: "tg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tg.png"
//   },
//   {
//     id: "218",
//     name: "Tokelau",
//     dial_code: "+690",
//     code: "tk",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tk.png"
//   },
//   {
//     id: "219",
//     name: "Tonga",
//     dial_code: "+676",
//     code: "to",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/to.png"
//   },
//   {
//     id: "220",
//     name: "Trinidad and Tobago",
//     dial_code: "+1868",
//     code: "tt",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tt.png"
//   },
//   {
//     id: "221",
//     name: "Tunisia",
//     dial_code: "+216",
//     code: "tn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tn.png"
//   },
//   {
//     id: "222",
//     name: "Turkey",
//     dial_code: "+90",
//     code: "tr",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tr.png"
//   },
//   {
//     id: "223",
//     name: "Turkmenistan",
//     dial_code: "+993",
//     code: "tm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tm.png"
//   },
//   {
//     id: "224",
//     name: "Turks and Caicos Islands",
//     dial_code: "+1649",
//     code: "tc",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tc.png"
//   },
//   {
//     id: "225",
//     name: "Tuvalu",
//     dial_code: "+688",
//     code: "tv",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/tv.png"
//   },
//   {
//     id: "226",
//     name: "Uganda",
//     dial_code: "+256",
//     code: "ug",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ug.png"
//   },
//   {
//     id: "227",
//     name: "Ukraine",
//     dial_code: "+380",
//     code: "ua",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ua.png"
//   },
//   {
//     id: "228",
//     name: "United Arab Emirates",
//     dial_code: "+971",
//     code: "ae",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ae.png"
//   },
//   {
//     id: "229",
//     name: "United Kingdom",
//     dial_code: "+44",
//     code: "gb",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/gb.png"
//   },
//   {
//     id: "230",
//     name: "United States",
//     dial_code: "+1",
//     code: "us",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/us.png"
//   },
//   {
//     id: "231",
//     name: "Uruguay",
//     dial_code: "+598",
//     code: "uy",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/uy.png"
//   },
//   {
//     id: "232",
//     name: "Uzbekistan",
//     dial_code: "+998",
//     code: "uz",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/uz.png"
//   },
//   {
//     id: "233",
//     name: "Vanuatu",
//     dial_code: "+678",
//     code: "vu",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/vu.png"
//   },
//   {
//     id: "234",
//     name: "Venezuela, Bolivarian Republic of",
//     dial_code: "+58",
//     code: "ve",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ve.png"
//   },
//   {
//     id: "236",
//     name: "Virgin Islands, British",
//     dial_code: "+1284",
//     code: "vg",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/vg.png"
//   },
//   {
//     id: "237",
//     name: "Virgin Islands, U.S.",
//     dial_code: "+1340",
//     code: "vi",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/vi.png"
//   },
//   {
//     id: "238",
//     name: "Wallis and Futuna",
//     dial_code: "+681",
//     code: "wf",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/wf.png"
//   },
//   {
//     id: "239",
//     name: "Yemen",
//     dial_code: "+967",

//     code: "ye",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/ye.png"
//   },
//   {
//     id: "240",
//     name: "Zambia",
//     dial_code: "+260",
//     code: "zm",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/zm.png"
//   },
//   {
//     id: "241",
//     name: "Zimbabwe",
//     dial_code: "+263",
//     code: "zw",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/zw.png"
//   },
//   {
//     id: "242",
//     name: "Viet Nam",
//     dial_code: "+84",
//     code: "vn",
//     flag: "https://staticsrc.squats.in/fittr/country_flag/vn.png"
//   }
// ]

// export const starAllianceOption = [
//   "JP",
//   "OZ",
//   "MS",
//   "ZH",
//   "TG",
//   "A3",
//   "OS",
//   "ET",
//   "SQ",
//   "TK",
//   "AC",
//   "AV",
//   "BR",
//   "SA",
//   "UA",
//   "CA",
//   "SN",
//   "LO",
//   "LX",
//   "US",
//   "NZ",
//   "CM",
//   "LH",
//   "NH",
//   "SK",
//   "TP"

// ]

// export const oneWorldOption = [
//   "AB",
//   "CX",
//   "JL",
//   "QF",
//   "S7",
//   "AA",
//   "AY",
//   "LA",
//   "QR",
//   "US",
//   "BA",
//   "IB",
//   "MH",
//   "RJ"

// ]

// export const skyTeam = [
//   "SU",
//   "AF",
//   "CZ",
//   "KL",
//   "RO",
//   "AR",
//   "AZ",
//   "OK",
//   "KE",
//   "VN",
//   "AM",
//   "CI",
//   "DL",
//   "ME",
//   "MF",
//   "UX",
//   "MU",
//   "KQ",
//   "SV"
// ]

// export const allAllianceOption = [

//   "JP",
//   "OZ",
//   "MS",
//   "ZH",
//   "TG",
//   "A3",
//   "OS",
//   "ET",
//   "SQ",
//   "TK",
//   "AC",
//   "AV",
//   "BR",
//   "SA",
//   "UA",
//   "CA",
//   "SN",
//   "LO",
//   "LX",
//   "US",
//   "NZ",
//   "CM",
//   "LH",
//   "NH",
//   "SK",
//   "TP",
//   "AB",
//   "CX",
//   "JL",
//   "QF",
//   "S7",
//   "AA",
//   "AY",
//   "LA",
//   "QR",
//   "US",
//   "BA",
//   "IB",
//   "MH",
//   "RJ",
//   "SU",
//   "AF",
//   "CZ",
//   "KL",
//   "RO",
//   "AR",
//   "AZ",
//   "OK",
//   "KE",
//   "VN",
//   "AM",
//   "CI",
//   "DL",
//   "ME",
//   "MF",
//   "UX",
//   "MU",
//   "KQ",
//   "SV"

// ]

// export const allianceName = {
//   ONE_WORLD: "One World",
//   STAR_ALLIANCE: "Star Alliance",
//   SKY_TEAM: "Sky Team",
//   ALL_CARRIERS: "All Carriers",
//   SPECIFIC_AIRLINES: "Specific Airlines"
// }

// export const grpItinenaryErrorMessage = {
//   NO_DATA_FOUND: "No data found"
// }

// export const contractType = {
//   LAND: "Land",
//   CRUISE: "Cruise",
//   AIR: "Air"
// }

// export const resultStatus = {
//   CACHE_EXPIRED: "CacheExpired",
//   CACHE_EXPIRED_MSG: "cached_expired"
// }

// export const defaultSsrOption = {
//   SELECT_MEAL: "Select Meal",
//   SELECT_ASSISTANCE: "Select Assistance",
//   SELECT_WHEELCHAIR: "Select Wheelchair"
// }
export const BookingFrom = {
  CONFIRMATION: "confirmation"
}

// export const BookingStatusCode = {
//   TF: "TF",
//   PNP: "PNP",
//   SC: "SC",
//   NTC: "NTC",
//   PT: "PT",
//   NP: "NP",
//   TC: "TC",
//   PNTC: "PNTC",
//   PD: "PD"
// }

// export const CardType = {
//   AMERICAN_EXPRESS: "american-express"
// }

// export const bookingStatusDesc = {
//   BOOKED_NEEDS_PAYMENT: "Booked - Needs Payment",
//   BOOKED_TICKETING_FAILED: "Booked - Ticketing Failed"
// }

// export const opratingCarrier = {
//   WS: "WS",
//   AC: "AC"
// }

// export const fareTypeConstant = {
//   NET: "Negotiated",
//   PUB: "Published"
// }

// export const defaultBillingForm = [
//   {
//     name: "Line 1",
//     label: "Line 1",
//     isRequired: true,
//     value: "",
//     changeMethod: "",
//     key: "line1",
//     isText: true
//   },
//   {
//     name: "Line 2",
//     label: "Line 2",
//     isRequired: false,
//     value: "",
//     changeMethod: "",
//     key: "line2",
//     isText: true
//   },
//   {
//     name: "Country",
//     isRequired: true,
//     label: "Country",
//     value: "",
//     selectedMethod: "setSelectedVal",
//     selectedValue: "selectedCountry",
//     key: "country",
//     isDropdown: true,
//     placeholder: "Select Country",
//     payloadValues: [
//       { key: "name", value: "name" },
//       { key: "code", value: "code" }
//     ],
//     dataToProcess: [
//       { key: "label", value: "country" },
//       { key: "name", value: "country" },
//       { key: "code", value: "iso2" },
//       { key: "city", value: "cities" },
//     ]
//   },
//   {
//     name: "State",
//     isRequired: true,
//     label: "State",
//     value: "",
//     changeMethod: "",
//     key: "state",
//     isDropdown: true,
//     placeholder: "Select State",
//     payloadValues: [
//       { key: "name", value: "name" }
//     ],
//     dataToProcess: [
//       { key: "label", value: "name" },
//       { key: "name", value: "name" },
//     ]
//   },
//   {
//     name: "City",
//     isRequired: true,
//     label: "City",
//     value: "",
//     changeMethod: "",
//     key: "city",
//     isDropdown: true,
//     placeholder: "Select City",
//     payloadValues: [
//       { key: "name", value: "name" }
//     ],
//     dataToProcess: [
//       { key: "label", value: "city" },
//       { key: "name", value: "city" },
//     ]
//   },
//   {
//     name: "Postal Code",
//     isRequired: true,
//     label: "Postal Code",
//     value: "",
//     changeMethod: "",
//     key: "postalCode",
//     isText: true
//   }
// ]

// export const fullmonthName = [{ value: 1, label: "Jan" }, { value: 2, label: "Feb" }, { value: 3, label: "Mar" }, { value: 4, label: "Apr" }, { value: 5, label: "May." },
// { value: 6, label: "Jun" }, { value: 7, label: "Jul" }, { value: 8, label: "Aug" }, { value: 9, label: "Sep" }, { value: 10, label: "Oct" }, { value: 11, label: "Nov" }, { value: 12, label: "Dec" },
// ];

// function generateYearArray() {
//   const today = new Date();
//   const currentYear = today.getFullYear();
//   const yearArray = [];
//   for (let year = currentYear; year >= 1900; year--) {
//     yearArray.push({ label: `${year}`, value: year });
//   }
//   return yearArray
// }
// export const dobYears = generateYearArray()

// export const calcCalendarDays = (month = null, year = null) => {
//   let calDays = [];
//   const days = new Date(year, month, 0).getDate();
//   for (let d = 1; d <= days; d++) {
//     calDays.push({ label: d, value: d })
//   }
//   return calDays;
// }

// export const taxIncreasedStatusArray = ["TaxIncreased", "TaxDecreased", "BaseAmountDecreasedAndTaxDecreased", "BaseAmountDecreasedAndTaxIncreased", "TaxChanged"];

export const cabinType = [
    { label: "Economy Class", value: "Economy" },
    { label: "Premium Economy", value: "PremiumEconomy" },
    { label: "Business Class", value: "Business" },
    { label: "First Class", value: "First" }
];

export const tabsOptions = [
    { label: "Round Trip", value: 0 },
    { label: "One Way", value: 1 },
    { label: "Multi City", value: 2 }
];

export const tabPanelOptions = [
    { label: "Return", value: 0 },
    { label: "OneWay", value: 1 },
    { label: "MultiCity", value: 2 }
];

export const FROMTO={
    FROM:"from",
    TO:"to"
};

export const TRIPTYPE={
    ROUNDTRIP:"Return",
    ONEWAY:"OneWay",
    MULTICITY:"MultiCity",
    ROUNDTRIP_CNAME: "RoundTrip"
};

export const UPLIFTTRIPTYPE = {
  "ONEWAY":"oneway",
  "ROUNDTRIP":"roundtrip",
  "MULTICITY":"open_jaw",
}

export const CREATE_SEGMENT = (departureLocation: string, arrivalLocation: string, departureDate: string) => {
    return {
        departureLocation,
        arrivalLocation,
        departureDate
    }
}

export const CHANGE_DATE_TO_LOCALE = (date: Date) => {
  if(!date || !(date instanceof Date))
    return ""
  return date.toLocaleDateString();
}

export const getPopulatedDataArray = (arrayLength: number, allowZero: boolean = false) => Array.from({ length: arrayLength }, (_, index) => ((allowZero && index === 0) ? index : index + 1));

export const PASSENGERTYPE={
    ADULT:"Adult",
    CHILD:"Child",
    INFANT:"InfantInLap"
}

export const TRIPDIRECTION = {
    OUTBOUND: "OUTBOUND",
    INBOUND: "INBOUND"
}

export const onlyDateFormat = (date: any) => {
    return dayjs(date, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00');
}

// To make String to title case
export const convertStringToTitleCase = (baggage: string) => {
  return baggage
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

// Process Branded Fares
export const getProcessesBrandedFare = (brandedFeatures: BrandedFeature[]) => {
  const subCodeMappings: Record<string, BrandedFeature> = {};
  const commercialNameMappings: Record<string, BrandedFeature> = {};
  brandedFeatures.forEach((feature) => {
    if (!subCodeMappings[feature.subCode]) {
      subCodeMappings[feature.subCode] = feature;
    }
    if (!commercialNameMappings[feature.commercialName]) {
      commercialNameMappings[feature.commercialName] = feature;
    }
  });
  return { commercialNameMappings, subCodeMappings };
};

// For branded fares
export const groupBySectionTitle = (features: any) => {
  return features.reduce((acc: any, feature: any) => {
    const { sectionTitle } = feature;
    if (!sectionTitle) return acc;

    if (!acc[sectionTitle]) {
      acc[sectionTitle] = [];
    }
    acc[sectionTitle].push(feature);
    return acc;
  }, {});
};

export const LOCPROVINCES = {
    "AB": "Alberta",
    "BC": "British Columbia",
    "MB": "Manitoba",
    "NB": "New Brunswick",
    "NL": "Newfoundland and Labrador",
    "NT": "Northwest Territories",
    "NS": "Nova Scotia",
    "NU": "Nunavut",
    "ON": "Ontario",
    "PE": "Prince Edward Island",
    "QC": "Quebec",
    "SK": "Saskatchewan",
    "YT": "Yukon Territory"
}

export const INSURANCEPLANCODE = {
    "RGIN" : "RGIN",
    "RGCX" : "RGCX",
    "REMU" : "REMU",
    "RPPP" : "RPPP",
    "DECLINED" : "DECLINED"
}

export const CALCTOTAL = {
    "amount" : "amount",
    "base" : "base",
}

export const INSURANCEDETAILCONFIG = {
    'redtag': {
      'en': {
        'RGIN': [
          "Emergency Medical, No age Limit",
          "Trip Cancellation and Interruption Coverage",
          "Baggage Delay, Loss, and Damage",
          "Delayed Return and Missed Connections",
        ],
        'RGCX': [
          "No Age Limit",
          "Trip Cancellation and Interruption Coverage",
          "Baggage Delay, Loss and Damage",
          "Delayed Return and Missed Connection",
        ],
        'REMU': [
          "No Medical Questionnaire Required",
          "Covered Medical Provider Expenses",
          "Toll-Free Assistance Centre",
          "Arrangement of Air Ambulance When Necessary"
        ],
        'RPPP': [
          "Applicable to Residents of Canada",
          "Trip Cancellation and Trip Interruption",
          "Baggage Delay, Loss and Damage",
          "Emergency Medical under the age of 74"
        ],
      },
      'fr': {
        'RGIN': [
          "Soins médicaux d’urgence, Aucune limite d’âge",
          "Assurance annulation et interruption de voyage",
          "Bagages perdus, endommagés et retardés",
          "Retour retardé et connexions manquées",
        ],
        'RGCX': [
          "Aucune limite d’âge",
          "Assurance annulation et interruption de voyage",
          "Bagages perdus, endommagés et retardés",
          "Retour retardé et connexions manquées",
        ],
        'REMU': [
          'Aucun questionnaire médical requis',
          'Frais de médecin couverts',
          'Centre d’assistance téléphonique',
          'Transport par ambulance aérienne lorsqu’il est nécessaire'
        ],
        'RPPP': [
          'Applicable aux résidents du Canada',
          'Annulation et interruption de voyage',
          'Bagages perdus, endommagés et retardés',
          'Soins médicaux d’urgence, moins de 74 ans'
        ],
      }
    },
    // Other insurance configurations as in the original class...
}

export const upliftErrorMessage = {
  1: 'Treat yourself! Upgrade your package, or add trip insurance to get your total over $500',
  2: 'Your total is too high! Pay Monthly is only available for trips from $500 to $15000.',
  4: 'Your departure date is too soon! Itineraries must have a departure date at least 3 day(s) away in order to be eligible for Uplift Pay Monthly.',
};

export const paymentlabel = {
  FULLPAYMENT:"fullPayment",
  UPLIFTPAYMENT:"upliftpayment"
}
export const INSURANCESTR = {
  "provinceChange" : "provinceChange",
  "paxChange" : "paxChange",
  "paxInsChange": "paxInsChange",
  "travelInsuranceDetails": "Travel Insurance Details",
  "comManulifePolicy" : "Manulife Policy",
  "comEmailInsConfMsg" : "You will receive a separate email outlining your full insurance policy from Manulife. Please check your inbox or spam folder for this information from confirmations@igoinsured.com",

  "comEmailInsDeclineMsg" : "You have declined travel Insurance. It is strongly recommended that you purchase travel insurance before you depart for your trip. Emergencies can occur anywhere. Travel insurance protects you against unforeseen circumstances, offering you a worry free travel experience. For more information on insurance options please contact us at 1.866.873.3824.",
  
  "comEmailInsCurrentlyProcessing" : "Your travel insurance booking is currently processing.You can call us at 1.866.873.3824 to resolve the issue.",

  "comDecline" : "DECLINED",
  "comDeclineMsg" : "You Have DECLINED Travel Insurance. Travel insurance is designed to offer you a worry free travelling experience. Emergencies can occur anywhere. Travel insurance protects you against unforseen circumstances.",
  "comError" : "Error",
  "comPending" : "Pending",
  "comConfirmed" : "Confirmed",
  "comEmailYourTravelInsurance" : "Your travel insurance is confirmed",
  "comEmailYourDeclinedTravelIns": "You have declined travel insurance.",
  "comEmailTravelInsurancePending" : "Your travel insurance is currently Pending.",
  "BillingInsuranceTotalConfirmed" : " Insurance Total:",
  "BillingInsuranceTotalDeclined" : " Insurance:",
 
}

export const stepperConfig = {
  SEARCH: [
    { StepperTitle: 'Select Flight', StepperFinish: false, StepperCount: 1, StepperActive: true },
    { StepperTitle: 'Booking', StepperFinish: false, StepperCount: 2, StepperActive: false },
    { StepperTitle: 'Confirmation', StepperFinish: false, StepperCount: 3, StepperActive: false }
  ],
  BOOKING: [
    { StepperTitle: 'Select Flight', StepperFinish: true, StepperCount: 1, StepperActive: false },
    { StepperTitle: 'Booking', StepperFinish: false, StepperCount: 2, StepperActive: true },
    { StepperTitle: 'Confirmation', StepperFinish: false, StepperCount: 3, StepperActive: false }
  ],
  CONFIRMATION: [
    { StepperTitle: 'Select Flight', StepperFinish: true, StepperCount: 1, StepperActive: false },
    { StepperTitle: 'Booking', StepperFinish: true, StepperCount: 2, StepperActive: false },
    { StepperTitle: 'Confirmation', StepperFinish: false, StepperCount: 3, StepperActive: true }
  ]
};

export const PROMOCODESTR = {
    "APPLYPROMOCODE": "Apply a promo code",
    "PREVIOUSPROMOREMOVAL": "Your previous Promo has been removed successfully.",
    "PLEASEENTERPROMOCODE": "Please Enter Promocode.",
    "ITSEEMSPROMOERROR": "It seems that there\'s an issue applying this Promo code to your booking. Please call ",
    "FORASSISTANCE": " for assistance",
    "PROMOAPPLIED": "Coupon code is applied successfully.",
    "PROMOTITLE": "Receive a $20 Amazon Gift Card with Flight Bookings.",
    "PROMOINVALIDMSG": "Please Enter Valid Promo code."
};

export const SortByOption = [
  { label: "Sort by Price (Low to High)", value: "PriceAsc" },
  { label: "Sort by Price (High to Low)", value: "PriceDesc" },
  { label: "Sort by Duration (Short to Long)", value: "DurationAsc" },
  { label: "Sort by Duration (Long to Short)", value: "DurationDesc" }
];

export const parseAmount = (value) => {
  return parseFloat(value).toFixed(2);
};