import React, { useState } from 'react';
import { Box, Button, Divider, Popover, Typography } from "@mui/material";
import WcIcon from '@mui/icons-material/Wc';
import CoffeeIcon from '@mui/icons-material/Coffee';
import { useTranslation } from 'react-i18next';

interface SeatProps {
    seatData: any;
    letter: string;
    isAvailable: boolean;
    onClickSeat: (letter: string, row: number, travellerId: string, isSwapping: boolean) => void;
    travellerId: string;
    row: number;
    isSwapping: boolean;
    isSeatDisabled: boolean;
}

const Seat = ({ seatData, letter, isAvailable, row, onClickSeat, travellerId, isSwapping, isSeatDisabled }: SeatProps) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getSeatButtonClass = () => {
        if (isSeatDisabled) return 'seats seat_Disabled';
        if (isAvailable && travellerId) {
            return 'seats seat_SelectActive';
        }
        if (isAvailable && (seatData.chargeable === "Paid" || seatData.chargeable === "PaidContactCarrier")) {
            return 'seats seat_Yellow';
        }
        return isAvailable ? 'seats seat_EmetyActive' : 'seats seat_Emety';
    };

    const handleSeatClick = () => {
        if (isSeatDisabled) { return }
        if (isAvailable && seatData.chargeable !== "Paid" && seatData.chargeable !== "PaidContactCarrier" && !seatData.characteristics.includes('NoSeat')) {
            onClickSeat(letter, row, travellerId, isSwapping);
        }
    };

    const buttonClass = getSeatButtonClass();

    return (
        <>
            <Button
                className={buttonClass}
                onClick={handleSeatClick}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{ position: 'relative' }}
            >
                {seatData?.limitations?.includes('NoSeatLavatory') && (
                    <WcIcon
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '16px',
                            color: 'gray'
                        }}
                    />
                )}
                {seatData?.limitations?.includes('NoSeatGalley') && (
                    <CoffeeIcon
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '16px',
                            color: 'gray'
                        }}
                    />
                )}
            </Button>
            <Popover
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 40, vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: -10 }}
                slotProps={{
                    paper: {
                        sx: {
                            overflow: 'visible',
                            backgroundColor: `#FFFFFF !important`,
                            borderRadius: '16px',
                            boxShadow: '0px 4px 18px rgba(97, 97, 97, 0.3)',
                            border: `1px solid #FFFFFF`,
                            minWidth: '300px',
                            maxWidth: '300px',

                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: -1,
                                right: 20,
                                width: 12,
                                height: 12,
                                backgroundColor: 'inherit',
                                transform: 'translateY(-50%) rotate(45deg)'
                            }
                        }
                    }
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1">
                        {`${t('Seat')}: ${row}${letter}`}
                    </Typography>
                    {seatData?.fare?.totalAmount && seatData?.chargeable === "Paid" && (
                        <Typography variant="body1">
                            {`${seatData?.fare?.totalAmount?.currency} ${seatData?.fare?.totalAmount?.amount}`}
                        </Typography>
                    )}
                </Box>
                <Divider />
                {seatData?.limitations?.includes('NoSeatLavatory') ? (
                    <Box sx={{ p: 2, pt: 1 }}>
                        <SeatInfoList data={["Lavatory"]} />
                    </Box>
                ) :
                    <Box sx={{ p: 2, pt: 1 }}>
                        <SeatInfoList title="Locations" data={seatData?.locations} />
                        <SeatInfoList title={t("Facilities")} data={seatData?.facilities} />
                        <SeatInfoList title="Characteristics" data={seatData?.characteristics} />
                        <SeatInfoList title="Limitations" data={seatData?.limitations} />
                    </Box>
                }
            </Popover>
        </>
    );
};

export default Seat;

interface SeatInfoProps {
    title?: string;
    data: string[];
}

const SeatInfoList = ({ title, data }: SeatInfoProps) => {
    if (!data || data.length === 0) return null;

    return (
        <Box  className="seatMapInfoDetails">
            {title && <Typography variant="body2" className='seatMapInfo_title' >{title}:</Typography>}
            <ul className='seatMapInfo_list'  >
                {data.map((item, index) => (item && item != "" &&
                    <li key={index} className='seatMapInfo_point'>
                        <Typography variant="body2">{item}</Typography>
                    </li>
                ))}
            </ul>
        </Box>
    );
};
