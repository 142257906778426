import SearchResultsComponent from "../components/searchResults/SearchResults";
import { ROUTES } from "../utility/constants";
import LoginPageComponent from "../components/login/loginPageComponent";
import BookingConfirmationComponent from "../components/confirmation/bookingConfirmation";
import BookingComponent from "../components/bookingComponent/BookingComponent";
import ErrorPageComponent from "../components/errorComponent/errorComponent";
import AllSearchResults from "../components/allSearchResults/AllSearchResults";

export const Routes = [
    // { path: "/", component: LoginPageComponent, exact: true }, // Example of exact route
    // { path: ROUTES.searchResultPage, component: SearchResultsComponent, exact: false }, // Example of non-exact route
    { path: "/", component: AllSearchResults, exact: true },
    { path: "/booking", component: BookingComponent, exact: true },
    { path: "/bookingConfirmation", component:BookingConfirmationComponent, exact:false },
    { path: "*", component: ErrorPageComponent }
];
