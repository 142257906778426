import React,{useEffect, useState} from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CardLabel } from '../CardLabel';
import { TRIPTYPE } from '../../../utils/constant';
import moment from 'moment';
import { formatDate, formatTime } from '../../../utils/util';
import { useTranslation } from 'react-i18next';

interface FlightData {
    flights: any;  // Define the proper type for flights
  }
  
  interface Segment {
    [key: string]: FlightData;
  }
  
  interface Props {
    segmentData?: Segment[];
  }


export default function BookingSummary(props: any) {
    const { t } = useTranslation();
    let [departureCity,setDepartureCity] = React.useState("")
    let [destinationCity,setDestinationCity] = React.useState("")
    let [departureCityCode,setDepartureCityCode] = React.useState("")
    let [destinationCityCode,setDestinationCityCode] = React.useState("")
    let [departureDate,setDepartureDate] = React.useState("")
    let [destinationDate,setDestinationDate] = React.useState("")
    const [segmentData, setSegmentData] = useState([]);

    let segments: Segment[] | undefined = props?.segmentData;
    let tripType=props?.tripType;

    useEffect(()=>{
        if(segments){
            let data = segments.map((segment: any) => {
                let modifiedSegment: any = Object.values(segment)[0];
                const {flights = []} = modifiedSegment;
                return {
                    departureCity: flights?.[0]?.departureAirportCity,
                    arrivalCity: flights?.[flights.length-1]?.arrivalAirportCity,
                    departureCode: flights?.[0]?.departureAirport,
                    arrivalCode: flights?.[flights.length-1]?.arrivalAirport,
                    departureDatetime: flights?.[0]?.departureDateTime,
                }
            });
            setSegmentData(data);    
        }
    },[segments])

    return (
        <Box className="conftion__wrapper">
            <Typography className='conftionTitle'>Booking Summary</Typography>
            <hr className='conftion_hr' />
            {segmentData?.length ? 
            segmentData.map(({departureCity,arrivalCity,departureCode,arrivalCode,departureDatetime}) => (<Box>
                <Box className="Booking_SummaryContent summary">
                    <CardLabel style={{width: "100%"}} title={`${departureCity}  (${departureCode}) - ${arrivalCity}  (${arrivalCode})`} subTitle="" />
                    <CardLabel title={`${t('Departure Date')}: ${formatDate(departureDatetime)} ${formatTime(departureDatetime)}`} subTitle="" />
                </Box>
            </Box>))
            : null}
        </Box>
    )
}